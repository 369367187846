import React, { useEffect, useState } from 'react';

const StatisticsV2Page = () => {
  //Attributes
  const [isReady, setIsReady] = useState(false);

  //Effect
  useEffect(() => {
    const iframe = document.getElementById('newBOIframe') as HTMLIFrameElement;

    const handleMessageFromIframe = (event: MessageEvent) => {
      if (event) {
        setIsReady(true);
        const message = {
          isInIframe: true,
          access_token: localStorage.getItem('ACCESS_TOKEN'),
        };

        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(message, '*');
        }
      }
    };

    window.addEventListener('message', handleMessageFromIframe);

    return () => {
      window.removeEventListener('message', handleMessageFromIframe);
    };
  }, [isReady]);

  //Render
  return (
    <div className='bg-gray-100 flex flex-col overflow-scroll w-full h-full z-0'>
      <iframe
        title='Screens Access iFrame'
        id='newBOIframe'
        src={`${process.env.REACT_APP_NEW_BO_URL}/statistics`}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 'calc(100% - 84px)', // Adjusted width to account for sidebar
          height: '100%',
          border: '0',
        }}
      />
    </div>
  );
};

export default StatisticsV2Page;
