import React, { useState } from "react";
import BusyIndicator from "../../components/busy-indicator";
import InputText from "../../components/input-text";
import InputWithLabel from "../../components/input-with-label";
import Translation from "../../components/translation";
import Modal from "react-modal";
import { ReactComponent as FoodizIcon } from "./assets/foodiz.svg";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import "./style.css";
import Popup from "../dashboard/components/popup";
import PopupButton from "../../components/popup-button";
import { customStyles } from "../dashboard/components/popup/customStyle";
import TokenService from "../../services/TokenService";
import { useHistory } from "react-router-dom";
import Service from "../../services/Service";

const Login = () => {
  // Attributes
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  async function handleLogin() {
    setIsLoading(true);
    try {
      const response = await TokenService.post(email, password);
      if (response) {
        const { access_token } = response;
        localStorage.setItem("ACCESS_TOKEN", access_token);
        history.push("/dashboard");
      } else {
        Service.errors.push("Unable to connect");
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleKeyDown(key: any) {
    if (key.key === "Enter") {
      if (email && password) {
        handleLogin();
      }
    }
  }

  return (
    <div className="login-container">
      <div className="login-form-container">
        <BusyIndicator query={{ isLoading, status: 'idle' }}>
          <div style={{ width: 77, height: 36 }}>
            <FoodizIcon style={{ width: "100%", height: "100%" }} />
          </div>
          <div className="login-title">
            <Translation>Welcome</Translation>
          </div>
          <div className="login-subtitle">
            <Translation>Please login to your account</Translation>
          </div>
          <InputWithLabel label="EMAIL">
            <InputText
              value={email}
              onChange={(event: any) => setEmail(event.target.value)}
              placeholder="insert email address"
              size="fill"
              style={{ borderWidth: 3 }}
              dataCy="email"
              readOnly={isLoading}
            />
          </InputWithLabel>
          <div style={{ marginTop: 12 }} />
          <InputWithLabel label="PASSWORD">
            <InputText
              onKeyDown={(key) => handleKeyDown(key)}
              type="password"
              value={password}
              onChange={(event: any) => setPassword(event.target.value)}
              placeholder="insert password"
              size="fill"
              style={{ borderWidth: 3 }}
              dataCy="password"
              readOnly={isLoading}
            />
          </InputWithLabel>
          <div 
            className={`login-button ${isLoading ? 'disabled' : ''}`} 
            onClick={!isLoading ? handleLogin : undefined} 
            data-cy="sign-in"
          >
            login
          </div>
        </BusyIndicator>
        <div className="login-footer">
          <span>
            <Translation>Can't login ?</Translation>
          </span>
          <br />
          <span>
            <Translation>Contact foodiz@foodiz.com</Translation>
          </span>
        </div>
      </div>
      <Modal style={customStyles} isOpen={isModalOpen}>
        <Popup
          title="Oups!"
          secondTitle="We could not log you in."
          subTitle="Your email or password is incorrect. Please try again."
          icon={<WarningIcon />}
          buttons={[
            <PopupButton
              backgroundColor="yellow"
              handleClick={() => setIsModalOpen(false)}
              title="Try again"
            />,
          ]}
        />
      </Modal>
    </div>
  );
};

export default Login;
